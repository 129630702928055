<template>
	<div class="avatar-cropper">
		<div v-if="dataUrl" class="avatar-cropper-overlay-inline">
			<div class="avatar-cropper__container">
				<div class="avatar-cropper__image-container">
					<Loader v-if="cropperLoading" />
					<img
						v-show="!cropperLoading"
						ref="img"
						:src="dataUrl"
						alt
						@load.stop="createCropper"
						@error="onImgElementError"
					/>
				</div>
				<FormError :error="error" />
				<div class="avatar-cropper__footer">
					<CButton danger @click.stop.prevent="cancel">
						{{ $t('global.cancel') }}
					</CButton>

					<CButton primary :loading="uploadingImage" @click.stop.prevent="uploadImage">
						{{ $t('global.crop-and-save') }}
					</CButton>
				</div>
			</div>
		</div>
		<input ref="input" :accept="cleanedMimes" class="avatar-cropper__input" type="file" @change="onFileInputChange" />
	</div>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import CButton from '@/shared/cbutton/CButton';
import FormError from '@/shared/forms/FormError';
import apiClient from '@/api';
import Loader from '@/shared/loader/Loader';

export default {
	name: 'AvatarCropper',
	components: { Loader, FormError, CButton },

	props: {
		value: {
			type: Boolean,
			default: false,
		},

		uploadHandler: {
			type: Function,
		},

		cropperOptions: {
			type: Object,
			default() {
				return {
					aspectRatio: 1,
					autoCropArea: 1,
					viewMode: 1,
					movable: false,
					zoomable: false,
				};
			},
		},

		outputQuality: {
			type: Number,
			default: 0.9,
		},

		mimes: {
			type: Array,
			default() {
				return ['image/png', 'image/gif', 'image/jpeg', 'image/bmp'];
			},
		},
	},
	emits: ['input', 'submit', 'error', 'cancel', 'changed', 'uploading', 'completed', 'uploaded'],

	data() {
		return {
			cropper: undefined,
			dataUrl: undefined,
			fileName: undefined,
			mimeType: undefined,
			error: null,
			cropperLoading: true,
			uploadingImage: false,
		};
	},

	computed: {
		cleanedMimes() {
			return this.mimes.join(', ').toLowerCase();
		},
	},

	watch: {
		value(value) {
			if (!value) return;
			this.pickImage();
			this.$emit('input', false);
		},
		dataUrl() {
			this.cropperLoading = true;
		},
	},
	mounted() {
		this.$emit('input', false);
	},
	methods: {
		destroy() {
			if (this.cropper) this.cropper.destroy();
			if (this.$refs.input) this.$refs.input.value = '';
			this.dataUrl = undefined;
		},

		cancel() {
			this.$emit('cancel');
			this.destroy();
		},

		onImgElementError() {
			this.error = 'failed-image-load';
		},

		pickImage() {
			if (this.$refs.input) this.$refs.input.click();
		},

		onFileChange(file) {
			if (this.cleanedMimes === 'image/*') {
				if (file.type.split('/')[0] !== 'image') {
					this.error = 'invalid-file-type';
					return;
				}
			} else if (this.cleanedMimes) {
				const correctType = this.cleanedMimes.split(', ').find((mime) => mime === file.type);

				if (!correctType) {
					this.error = 'invalid-file-type';
					return;
				}
			}

			const reader = new FileReader();
			reader.onload = (e) => {
				this.dataUrl = e.target.result;
				this.$emit('shown');
			};

			reader.readAsDataURL(file);

			this.fileName = file.name || 'unknown';
			this.mimeType = file.type;
		},

		onFileInputChange(e) {
			if (!e.target.files || !e.target.files[0]) return;

			this.onFileChange(e.target.files[0]);
		},

		createCropper() {
			this.cropperLoading = true;
			this.cropper = new Cropper(this.$refs.img, {
				...this.cropperOptions,
				ready: () => (this.cropperLoading = false),
			});
		},

		getFilename() {
			return this.fileName;
		},

		async uploadImage() {
			this.uploadingImage = true;
			this.cropper.getCroppedCanvas(this.outputOptions).toBlob(
				async (blob) => {
					const form = new FormData();
					form.append('file', blob, this.getFilename());

					try {
						const response = await apiClient.updateUserProfilePicture(form);
						this.$emit('uploaded', {
							form,
							response,
						});
						this.destroy();
					} catch (error) {
						console.error(error.response);
						this.error = 'image-upload-failed';
					} finally {
						this.$emit('completed');
						this.uploadingImage = false;
					}
				},
				this.mimeType,
				this.outputQuality
			);
		},
	},
};
</script>

<style lang="scss">
.avatar-cropper {
	&__wrapper {
		position: initial;
	}

	&__input {
		display: none;
	}

	&__image-container {
		position: relative;
		max-width: 400px;
		height: 300px;
	}

	img {
		max-width: 100%;
		height: 100%;
	}

	&__footer {
		display: flex;
		align-items: stretch;
		align-content: stretch;
		justify-content: space-between;

		.button {
			margin: 20px 0;
		}

		.button:first-child {
			margin-right: 0.5rem;
		}
	}
}

.cropper-view-box,
.cropper-face {
	border-radius: 50%;
}
</style>
