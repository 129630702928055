<template>
	<transition name="modal-fade">
		<div class="modal-backdrop">
			<div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalTitle">
				<div class="icon-header">
					<img src="../assets/icons/warning.svg" />
					<button type="button" class="btn-close" @click="close" aria-label="Close modal">x</button>
				</div>
				<header class="modal-header" id="modalTitle">
					<slot name="header"> Confirmation modal</slot>
				</header>

				<section class="modal-body" id="modalDescription">
					<slot name="body"> </slot>
				</section>

				<div>
					<div class="action-buttons">
						<CButton danger @click="submitRequest" :loading="this.loading">
							{{ $t('actions.confirm') }}
						</CButton>
					</div>
					<div class="action-buttons">
						<CButton secondary @click="close">
							{{ $t('global.cancel') }}
						</CButton>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import CButton from '@/shared/cbutton/CButton';
import apiClient from '@/api';

export default {
	name: 'ConfirmationModal',
	components: {
		CButton,
	},
	data() {
		return {
			loading: false,
		};
	},
	methods: {
		close() {
			this.$emit('close');
		},
		async submitRequest() {
			this.loading = true;
			await apiClient.requestAccountTermination();
			this.loading = false;
			const dateNow = new Date().toISOString();
			this.$emit('update', dateNow);
			this.close();
		},
	},
};
</script>

<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.modal-backdrop {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.3);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	position: relative;
	background: #21232b;
	overflow-x: auto;
	display: flex;
	flex-direction: column;
	width: 433px;
	height: 394px;
	border-radius: 8px;
	border: 1px;
}

.modal-header,
.modal-footer {
	padding: 15px;
	display: flex;
}

.modal-header {
	position: relative;
	color: white;
	justify-content: space-between;
	font-weight: 700;
	font-size: 26px;
	line-height: 32px;
	font: equip;
	align: center;
}

.modal-body {
	right: auto;
	bottom: auto;

	outline: none;
	color: #84899c;
	font: equip;
	weight: 400;
	line-height: 16px;
	align: center;
	padding: 16px;
	display: flex;
	flex-direction: column;
}

.confirmation-modal-btn {
	position: absolute;
	bottom: 5px;
	width: 45%;
}

.modal-fade-enter,
.modal-fade-leave-to {
	opacity: 0;
}

.icon-header {
	margin-top: 2%;
	height: 50px;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
	transition: opacity 0.5s ease;
}

.btn-close {
	background-color: #242323;
	font-size: 40px;
	border: 0;
	position: absolute;
	top: 2px;
	right: 2px;
}

.action-buttons {
	width: 100%;
}
</style>
